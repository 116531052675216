/**
* Estilos para el slider categorias
*/
/* Estilos generales de las tarjetas */
.category-card {
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: none;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    height: 450px; /* Aumentar la altura de las tarjetas */
  }
  
  .category-card:hover {
    transform: translateY(-10px);
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .category-image {
    object-fit: cover;
    height: 300px; /* Ajustar la altura de la imagen dentro de la tarjeta */
    width: 100%;
    border-radius: 8px;
  }
  
  .category-title {
    font-weight: bold;
    font-size: 1.25rem;
  }
  
  .category-button {
    background-color: #007bff;
    border-color: #007bff;
    margin-top: 10px;
  }
  
  h1.display-4 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    color: #343a40;
  }
  /* Estilos personalizados para los indicadores (puntos) del carrusel */
.custom-carousel .carousel-indicators {
    bottom: -50px; /* Separar los puntos del carrusel */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 12px; /* Separación entre los puntos */
  }

  .custom-carousel .carousel-indicators button {
    background-color: grey; /* Color de fondo de los indicadores */
    width: 12px;
    height: 12px;
    border-radius: 50%; /* Hacer los indicadores circulares */
    border: none; /* Eliminar el borde */
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .custom-carousel .carousel-indicators li {
    background-color: grey; /* Color del indicador */
    width: 14px; /* Tamaño del punto */
    height: 14px;
    border-radius: 50%; /* Hacer los puntos redondos */
    border: none; /* Eliminar cualquier borde por defecto */
    transition: background-color 0.3s ease, transform 0.3s ease;
    position: relative;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2); /* Añadir sombra */
  }
  
  .custom-carousel .carousel-indicators .active {
    background-color: #0056b3; /* Color más oscuro cuando está activo */
    transform: scale(1.3); /* Aumentar el tamaño del punto activo */
  }
  
  .custom-carousel .carousel-indicators li:hover {
    background-color: #0056b3; /* Cambiar el color al hacer hover */
  }
  
  /* Eliminar cualquier borde no deseado */
.custom-carousel .carousel-indicators button:before {
    content: none !important;
  }
  /* Eliminar cualquier estilo predeterminado del indicador del carrusel de Bootstrap */
  .custom-carousel .carousel-indicators li:before {
    content: none !important; /* Eliminar cualquier estilo extraño como bordes negros */
  }
  
  
  /* Estilos para las flechas de navegación */
  .custom-arrow {
    color: rgba(0, 123, 255, 0.7); /* Color semitransparente */
    font-size: 2rem; /* Tamaño de las flechas */
  }
  
  .custom-arrow:hover {
    color: rgba(0, 123, 255, 1); /* Color cuando se hace hover */
  }
  
  .custom-carousel .carousel-control-prev {
    left: -60px; /* Mover la flecha izquierda fuera del área de la tarjeta */
  }
  
  .custom-carousel .carousel-control-next {
    right: -60px; /* Mover la flecha derecha fuera del área de la tarjeta */
  }
  
  
/**
* Fin Estilos para el slider categorias
*/