.table_class{
    background-color: white;
    border-radius: 20px;
    margin: 1%;
    padding: 2%;
}
.react-bootstrap-table-pagination-total{
    display:none
}

.table th {
    background-color: #f5f8fb;
    color: #262B40;
    border: none;
}
.seleccion{
    background-color: #f5f8fb;
}
.accordion-item-info{
    background-color: #e1e8f34d !important;
}
.accordion-button-info{
    background-color: #e7eaef !important;
}
.table-bording{
    overflow: scroll;
}
.table_class_informe{
    background-color: white;
    /* border-radius: 20px; */
    margin: 5px;
    padding: 5px;
}

.form_table_class_informe{
    width: 90em;
}
.var_form_table_class_informe{
    width: 150em;
}
.textCenter{
    text-align: center;
}
.textRequired{
    color:red
}

/**
* Autollenado
*/
datalist {
    position: absolute;
    max-height: 20em;
    border: 0 none;
    overflow-x: hidden;
    overflow-y: auto;
  }
  
  datalist option {
    font-size: 0.8em;
    padding: 0.3em 1em;
    background-color: #ccc;
    cursor: pointer;
  }
  
  /* option active styles */
  datalist option:hover,
  datalist option:focus {
    color: #fff;
    background-color: #036;
    outline: 0 none;
  }
  
  #browserdata option {
    font-size: 1.8em;
    padding: 0.3em 1em;
    background-color: #ccc;
    cursor: pointer;
  }

/**
* IMAGENES
**/
input[type="file"]#idDocUpd{
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    font-family: 'lato';
    text-align: right;
}
label[for="idDocUpd"] {
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    background-color: #4caf50;
    display: inline-block;
    transition: all .5s;
    cursor: pointer;
    padding: 5px 10px !important;
    width: fit-content;
    font-family: 'lato';
    text-align: center;
    margin-top: 10px;
}
input[type="file"]#idDoc{
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
    font-family: 'lato';
    text-align: right;
}
label[for="idDoc"] {
    border-radius: 5px;
    font-size: 17px;
    color: #fff;
    background-color: #4492c8;
    display: inline-block;
    transition: all .5s;
    cursor: pointer;
    padding: 5px 10px !important;
    width: fit-content;
    font-family: 'lato';
    text-align: center;
    margin-top: 10px;
}
.modal-footer {
    display: block;
}

.form-check-label{
    margin: 0px 10px 0px 10px;
}

/*Slidebar*/
.content {
    margin-left: 260px;
    transition: margin-left 0.3s ease;
  }
  
  .content-expanded {
    margin-left: 0;
    transition: margin-left 0.3s ease;
  }
  
  .menu {
    width: 260px;
    /* Define otros estilos del menú */
  }
  
  .menu-collapsed {
    display: none;
  }
/* .sidebar */

.image-container {
    width: 100%;
    height: 300px; /* Puedes ajustar esta altura según tus necesidades */
    overflow: hidden;
  }
  
  .card-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Mantiene la proporción de la imagen y la ajusta al contenedor */
  }